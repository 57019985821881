import { useSignupMutation } from "../auth/api/mutations"

const withSignup = (WrappedComponent) => {
    return () => {
        const [signupMutation] = useSignupMutation()
        const signup = (data) => {
            return new Promise((resolve, reject) => {
                signupMutation({ user: data }).unwrap()
                    .then(res => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        }
        return <WrappedComponent signup={signup} />
    }
}

export default withSignup