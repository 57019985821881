import React, { useState } from 'react'
import withSignup from '../../../../features/signup/withSignup'
import Div from '../../../../components/Div'
import Flex from '../../../../components/Flex'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import { rootStyles } from '../../../../style/shared'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { AUTH_PAGE, SALONS_PAGE, SALON_RESERVATION_PAGE } from '../../../../routes/navigation_routes'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentUser } from '../../../../redux/features/userslice'
import toast from 'react-hot-toast'
import { setFrom } from '../../../../redux/features/navigationSlice'

const WrappedSignUpAndRedirectToReservation = ({ signup }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { currentSalon } = useSelector(state => state.salons)
    const {
        register,
        handleSubmit,
        // watch,
        formState: { errors },
    } = useForm({
        values: {
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            password: "",
            city: "",
            address: ""
        }
    })
    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = (data) => {
        setIsLoading(true)
        signup(data).then(res => {
            console.log("data in signup: ", res)
            dispatch(setCurrentUser(res))
            toast.success(`Bienvenue ${res.user.first_name}`, {
                duration: 4000,
                position: 'top-center',

                // Styling
                style: {},
                className: '',

                // Custom Icon
                icon: '🖐️',

                // Change colors of success/error/loading icon
                iconTheme: {
                    primary: '#fff',
                    secondary: '#000',
                },

                // Aria
                ariaProps: {
                    role: 'status',
                    'aria-live': 'polite',
                },
            });
            dispatch(setFrom(AUTH_PAGE + SALONS_PAGE + currentSalon.id + "/" + SALON_RESERVATION_PAGE))
            return navigate(AUTH_PAGE + SALONS_PAGE + currentSalon.id + "/" + SALON_RESERVATION_PAGE, { replace: true })
        }).catch(err => {
            console.log({ err });
        }).finally(() => {
            setIsLoading(true)
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Flex align={"center"} gap={10} flex={1} wrap={"wrap"}>
                <Input
                    label={"Prénom"}
                    placeholder={"Prénom"}
                    type={"text"}
                    error={errors.first_name?.message}
                    register={{ ...register("first_name", { required: "Prénom obligatoire" }) }}
                />
                <Input
                    label={"Nom"}
                    placeholder={"Nom"}
                    type={"text"}
                    error={errors.last_name?.message}
                    register={{ ...register("last_name", { required: "Nom obligatoire" }) }}
                />
                <Input
                    label={"Téléphone portable"}
                    placeholder={"Entrez vore téléphone"}
                    type={"text"}
                    error={errors.phone?.message}
                    register={{ ...register("phone", { required: "Téléphone portable obligatoire" }) }}
                />
                <Input
                    optional={true}
                    label={"Ville"}
                    placeholder={"Ville"}
                    type={"text"}
                    register={{ ...register("city") }}
                />
                <Input
                    label={"Adresse"}
                    placeholder={"Adresse"}
                    type={"text"}
                    register={{ ...register("address") }}
                />
                <Input
                    label={"Email"}
                    placeholder={"Email"}
                    type={"email"}
                    error={errors.email?.message}
                    register={{ ...register("email", { required: "Email obligatoire" }) }}
                />
                <Input
                    isPassword
                    label={"Mot de passe"}
                    placeholder={"Mot de passe"}
                    type={"password"}
                    error={errors.password?.message}
                    register={{ ...register("password", { required: "Mot de passe obligatoire" }) }}
                />
            </Flex>
            <Div mt={20} mb={20}>
                <Button
                    width={100}
                    letterSpacing={3}
                    fontSize={rootStyles.fontSize.sm}
                    fontWeight={600}
                    textTransform="uppercase"
                    paddingVertical={rootStyles.padding.md}
                    borderWidth={2}
                    borderStyle="solid"
                    borderColor={rootStyles.colors.action}
                    borderRadius={rootStyles.borderRadius.xl}
                    backgroundColor={rootStyles.colors.action}
                    color={rootStyles.colors.primary}
                // hoverBackgroundColor={rootStyles.colors.primary}
                // hoverColor={rootStyles.colors.action}
                // width={100}
                >
                    {
                        isLoading ? "Inscription en cours..." : "Créer un compte"
                    }
                </Button>
            </Div>
        </form >
    )
}

const SignUpAndRedirectToReservation = withSignup(WrappedSignUpAndRedirectToReservation)

export default SignUpAndRedirectToReservation