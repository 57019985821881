import React from "react"
import appLogo from "../assets/images/kiliya.jpg"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { AUTH_PAGE, SALONS_PAGE } from "../routes/navigation_routes"

const Logo = () => {
    const navigate = useNavigate()
    const { currentUser } = useSelector(state => state.user)
    return (
        <img
            src={appLogo}
            alt="Kiliya"
            style={{ width: "100px", cursor: "pointer" }}
            onClick={() => !currentUser.access_token ? navigate("/") : navigate(AUTH_PAGE + SALONS_PAGE)}
        />
    )
}

export default Logo