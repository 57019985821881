import React from 'react'
import { useSelector } from 'react-redux'

const ReservationCard = ({ reservationCardItem: ReservationCardItem }) => {
    const { currentSalonReservations } = useSelector(state => state.reservations)
    return (
        <div>
            {
                currentSalonReservations?.length ? currentSalonReservations?.map(reservation => ReservationCardItem({ reservation })) : "Pas de réservations"
            }
        </div>
    )
}

export default ReservationCard