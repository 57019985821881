import { createSlice } from "@reduxjs/toolkit";

const navigationSlice = createSlice({
    name: "navigation",
    initialState: {
        from: ""
    },
    reducers: {
        setFrom: (state, { payload }) => {
            return {
                ...state,
                from: payload
            }
        }
    }
})
export const { setFrom } = navigationSlice.actions
export default navigationSlice.reducer