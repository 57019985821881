import { createContext, useState } from "react";

export const SidebarDrawerContext = createContext()

const SidebarDrawerProvider = ({ children }) => {
    const [visible, setVisible] = useState(false)

    return <SidebarDrawerContext.Provider value={{
        visible,
        openSidebarDrawer: () => setVisible(true),
        closeSidebarDrawer: () => setVisible(false),
    }}>
        {children}
    </SidebarDrawerContext.Provider>
}

export default SidebarDrawerProvider