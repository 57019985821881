import Accordion from "react-bootstrap/Accordion";

const AccordionContainer = ({ children }) => {
    return (
        <Accordion defaultActiveKey="0">
            {children}
        </Accordion>
    )
}

export default AccordionContainer