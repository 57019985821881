import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
    persistReducer, persistStore
} from "redux-persist";
import storage from "redux-persist-indexeddb-storage";
import salonSlice from "./features/salonSlice";
import { kiliyaApi } from "./rtkquery";
import userslice from "./features/userslice";
import reservationsSlice from "./features/reservationSlice";
import categorySlice from "./features/categorySlice";
import navigationSlice from "./features/navigationSlice";

const persistConfig = {
    key: "kiliya",
    storage: storage("kiliya"),
    // whitelist: ["salons", "reservations"]
}

const rootReducers = combineReducers({
    [kiliyaApi.reducerPath]: kiliyaApi.reducer,
    salons: salonSlice,
    reservations: reservationsSlice,
    categories: categorySlice,
    user: userslice,
    navigation: navigationSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducers)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }).concat(kiliyaApi.middleware),
})

// setupListeners(store.dispatch)

export const persistor = persistStore(store);