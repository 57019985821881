import { useSigninMutation } from "./api/mutations"

const withLogin = (WrappedComponent) => {
    return () => {
        const [loginMutation] = useSigninMutation()
        const login = (data) => {
            return new Promise((resolve, reject) => {
                loginMutation({ user: data }).unwrap()
                    .then(res => {
                        resolve(res)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        }
        return <WrappedComponent login={login} />
    }
}

export default withLogin