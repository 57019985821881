import React from "react"
import Select from "react-select"
import { rootStyles } from "../style/shared"

const defaultOptions = [
    { value: 'chocolate', label: 'Spa' },
    { value: 'strawberry', label: 'Massage' },
    { value: 'vanilla', label: 'Epilation' }
]

const SelectOptions = ({
    width,
    padding,
    borderWidth,
    options = defaultOptions,
    placeholder,
    onChange,
    defaultValue
}) => {
    return (
        <Select
            defaultValue={defaultValue}
            placeholder={placeholder}
            options={options}
            onChange={(value) => onChange(value)}
            styles={{
                control: (baseStyles, state) => {
                    // console.log({ baseStyles, state });
                    return {
                        ...baseStyles,
                        borderColor: state.isFocused ? `${rootStyles.colors.action} !important` : rootStyles.colors.inputBorderColor,
                        cursor: "pointer",
                        padding: padding ?? '5px',
                        borderWidth: borderWidth ?? "2px",
                        outline: 'none !important',
                        outlineColor: state.isFocused ? `${rootStyles.colors.action} !important` : rootStyles.colors.action,
                        boxShadow: 'none',
                        width: width ? `${width}px` : "100%",
                    }
                },
            }}
        />
    )
}

export default SelectOptions