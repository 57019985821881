import React, { Suspense } from 'react'
import Header from './header/Header'
import Navbar from './navbar/Navbar'
import Footer from './footer/Footer'
import { Navigate, Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { rootStyles } from '../style/shared'
import { useSelector } from 'react-redux'
import { AUTH_PAGE, SALONS_PAGE } from '../routes/navigation_routes'
import PageLoading from '../features/reservation/components/PageLoading'

export const MainContaintPane = styled.main`
background-color: ${rootStyles.colors.appBgColor}
`

const AppShell = () => {
    const { currentUser } = useSelector(state => state.user)
    const { currentCategory } = useSelector(state => state.categories)
    const { from } = useSelector(state => state.navigation)

    return (!currentUser?.access_token && !currentUser?.user?.id) ? <Suspense fallback={<PageLoading />} >
        <Header />
        <Navbar />
        <MainContaintPane>
            <Outlet />
        </MainContaintPane>
        <Footer />
    </Suspense> : <Navigate
            to={from ?? AUTH_PAGE + SALONS_PAGE + "?category=" + currentCategory.id}
        replace={true}
    />
}

export default AppShell