import React, { useState } from "react"
import styled from 'styled-components'
import { useSelector } from "react-redux"
import { AiOutlineLogout } from "react-icons/ai"
import { CiMenuFries } from "react-icons/ci"

import { rootStyles } from "../../style/shared"
import Flex from "../../components/Flex"

import Button from "../../components/Button"
import GoogleDocsBtn from "../../pages/home/components/GoogleDocsBtn"
import Logo from "../../components/Logo"
import DropDown from "./AvatarDropDown"
import { useSidebarDrawerContext } from "../../hooks/useSidebarDrawerContext"
import SidebarDrawer from "../Drawer/SidebarDrawer"
import { useNavigate } from "react-router-dom"
import { AUTH_PAGE, RESERVATIONS_PAGE } from "../../routes/navigation_routes"

const HeaderPane = styled.header`
    // height: 70px;
    position: relative;
    z-index:2;
    background-color: ${rootStyles.colors.primary};
    max-width: ${rootStyles.width.appMaxWidth}px;
    margin: 0 auto;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 0px;
`
const HeaderFluidPane = styled.header`
    position: relative;
    background-color: ${rootStyles.colors.primary};
    z-index: 99;
   box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
   padding: 10px ${rootStyles.padding.md}px;
   `
const RightHeaderPane = styled.div`
    @media (max-width: 600px) {
        display: none;
    }
`
const HumberguerPane = styled.div`
    display: none;
    @media (max-width: 600px) {
        display: block;
    }
`
// const LinkPane = styled(Link)`
//     text-decoration: none;
//     font-weight: 500;
//     display: inline-block;
//     margin-bottom: 10px;
//     color: ${rootStyles.colors.inputBorderColor};
//     // border-bottom: 1px solid ${rootStyles.colors.inputPlaceHolderColor};
//     width: 100%;
//     text-align: center;
//     // padding-bottom: 10px;

//     &:hover{
//         color: ${rootStyles.colors.action}
//     }
// `

const Header = () => {
    const { currentUser } = useSelector(state => state.user)
    const [showDropdown, setShowDropdown] = useState(false);
    const { openSidebarDrawer } = useSidebarDrawerContext()
    const navigate = useNavigate()

    return (
        <>
            <SidebarDrawer />
            <HeaderFluidPane>
                <HeaderPane>
                    <Logo />
                    <RightHeaderPane>
                        <Flex gap={10} wrap={"wrap"} align={"center"}>
                            {
                                (!currentUser?.access_token && !currentUser?.user?.id) ? <>
                                    <GoogleDocsBtn label="Devenir Partenaire" fontSize={rootStyles.fontSize.sm - 2} />
                                    <Button
                                        letterSpacing={3}
                                        fontSize={rootStyles.fontSize.sm - 2}
                                        fontWeight={500}
                                        textTransform="uppercase"
                                        paddingHorizontal={20}
                                        borderWidth={2}
                                        borderStyle="solid"
                                        borderColor={rootStyles.colors.action}
                                        borderRadius={rootStyles.borderRadius.xl}
                                        backgroundColor={rootStyles.colors.primary}
                                        color={rootStyles.colors.action}
                                        hoverBackgroundColor={rootStyles.colors.action}
                                        hoverColor={rootStyles.colors.primary}
                                        height={20}
                                    >
                                        S'identifier
                                    </Button>
                                </> : <>
                                    <Button
                                        onClick={() => navigate(AUTH_PAGE + RESERVATIONS_PAGE)}
                                        letterSpacing={3}
                                        fontSize={rootStyles.fontSize.sm - 2}
                                        fontWeight={500}
                                        textTransform="uppercase"
                                        paddingHorizontal={20}
                                        borderWidth={2}
                                        borderStyle="solid"
                                        borderColor={rootStyles.colors.action}
                                        borderRadius={rootStyles.borderRadius.xl}
                                        backgroundColor={rootStyles.colors.primary}
                                        color={rootStyles.colors.action}
                                        hoverBackgroundColor={rootStyles.colors.action}
                                        hoverColor={rootStyles.colors.primary}
                                        height={20}
                                    >
                                        Mes reservations
                                    </Button>
                                    <DropDown
                                        showDropdown={showDropdown}
                                        setShowDropdown={() => setShowDropdown(!showDropdown)}
                                    >
                                        <Button
                                            letterSpacing={3}
                                            fontSize={rootStyles.fontSize.sm - 2}
                                            fontWeight={500}
                                            textTransform="uppercase"
                                            paddingHorizontal={10}
                                            borderWidth={2}
                                            borderStyle="solid"
                                            borderColor={"red"}
                                            borderRadius={rootStyles.borderRadius.xl}
                                            backgroundColor={rootStyles.colors.primary}
                                            color={"red"}
                                            height={20}
                                        >
                                            <Flex align={"center"}>
                                                <AiOutlineLogout />   Deconnexion
                                            </Flex>
                                        </Button>

                                        </DropDown>
                                    </>
                            }

                        </Flex>
                    </RightHeaderPane>
                    <HumberguerPane>
                        {/* <Button
                        onClick={() => openSidebarDrawer()}
                        letterSpacing={3}
                        fontSize={rootStyles.fontSize.sm - 2}
                        fontWeight={500}
                        textTransform="uppercase"
                        paddingHorizontal={15}
                        borderWidth={2}
                        borderStyle="solid"
                        borderRadius={rootStyles.borderRadius.xl}
                        backgroundColor={rootStyles.colors.primary}
                        color={rootStyles.colors.action}
                    > */}
                        <CiMenuFries onClick={() => openSidebarDrawer()} size={20} />
                        {/* </Button> */}
                    </HumberguerPane>
                </HeaderPane>
            </HeaderFluidPane>
        </>
    )
}

export default Header