import React from 'react'
import styled from 'styled-components'
import { AiOutlineClockCircle, AiOutlineDelete } from 'react-icons/ai'
import { LiaMoneyBillWaveSolid } from "react-icons/lia"
import { AiOutlineCalendar } from "react-icons/ai"

import { rootStyles } from '../../../../style/shared'
import Flex from '../../../../components/Flex'
import Div from '../../../../components/Div'
import { ServiceTimePane } from '../../../salons/ui/components/SalonServiceCardItem'
import Dot from '../../../../components/Dot'
import Button from '../../../../components/Button'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { AUTH_PAGE, SALONS_PAGE } from '../../../../routes/navigation_routes'

const CardPane = styled.div`
    padding: ${rootStyles.padding.md}px;
    background-color: ${rootStyles.colors.salonServiceBorder};
    border: 1px solid ${rootStyles.colors.inputBorderColor};
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    gap: 20px;

    @media screen and (max-width: 550px) {
        flex-direction: column;
        padding: 0;
    }
`
const ImagePane = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: contain;

    @media screen and (max-width: 550px) {
        width: 100%;
        height: 200px;
    }
`
const DatePane = styled.h6`
    font-size: ${rootStyles.fontSize.md - 3}px;
    font-weight: 500
`
const ServicePane = styled.p`
    font-weight: 500
`
export const AdressePane = styled.p`
    text-decoration: underline;
    font-size: ${rootStyles.fontSize.md - 3}px;
    font-weight: 300;
`
const ActionPane = styled.button`
    unset: all;
    border-bottom: 1px solid gray;
    padding: 10px 5px;
    flex: 1;
    min-width: 180px;

    @media screen and (max-width: 900px){
        width: 100%
    }
`

const ReservationHistoryCardItem = ({ reservation }) => {
    return (
        <CardPane>
            <ImagePane src='https://cdn-icons-png.flaticon.com/512/3790/3790422.png' alt='Reservation historique' />
            <Div className={"p-3 pt-0"}>
                <DatePane>
                    {`${new Date(reservation.date).toLocaleDateString("fr-Fr", {
                        // month: "narrow",
                        dateStyle: "full",
                    })}`} à {`${new Date(reservation.appointment_service[0]?.start).getHours()}h:${new Date(reservation.appointment_service[0]?.start).getMinutes()}`}
                </DatePane>
                <ServicePane>
                    {reservation.appointment_service[0]?.service.name} - {" "}
                    <Link to={AUTH_PAGE + SALONS_PAGE + reservation.appointment_service[0]?.salon.id}>
                        {reservation.appointment_service[0]?.salon.name}
                    </Link>
                </ServicePane>
                <Div mt={5}>
                    <Flex align={"center"} gap={5}>
                        <HiOutlineLocationMarker /><AdressePane>{reservation.appointment_service[0]?.salon.address}</AdressePane>
                    </Flex>
                </Div>
                <ServiceTimePane>
                    <Flex align={"center"} gap={5}>
                        <AiOutlineClockCircle /> {reservation.appointment_service[0]?.service.duration} min
                    </Flex>
                </ServiceTimePane>
                <Flex align={"center"}>
                    <Dot /> <Flex gap={5} align={"center"}><LiaMoneyBillWaveSolid />{reservation.appointment_service[0]?.service?.price} {reservation.appointment_service[0]?.salon.currency}</Flex> <Dot />
                    <Flex align={"center"}>
                        <AiOutlineCalendar /><span>Avec {`${reservation.appointment_service[0]?.employee?.first_name} ${reservation.appointment_service[0]?.employee?.last_name}`}</span>
                    </Flex>
                </Flex>
                <Div mt={30}>
                    <Flex wrap={"wrap"} gap={10} align={"center"}>
                        <ActionPane>
                            <AiOutlineCalendar /> <span>Ajouter a mon agenda</span>
                        </ActionPane>
                        <ActionPane>
                            <AiOutlineDelete /> <span>Annuler le RDV</span>
                        </ActionPane>
                        <Flex flex={1} minwidth={190}>
                            <Button
                                letterSpacing={3}
                                fontSize={rootStyles.fontSize.sm - 2}
                                fontWeight={500}
                                textTransform="uppercase"
                                paddingHorizontal={10}
                                borderWidth={2}
                                borderStyle="solid"
                                borderColor={rootStyles.colors.action}
                                borderRadius={rootStyles.borderRadius.xl}
                                backgroundColor={rootStyles.colors.primary}
                                color={rootStyles.colors.action}
                                hoverBackgroundColor={rootStyles.colors.action}
                                hoverColor={rootStyles.colors.primary}
                                width={100}
                            >
                                Deplacer le RDV
                            </Button>
                        </Flex>
                    </Flex>
                </Div>
            </Div>
        </CardPane>
    )
}

export default ReservationHistoryCardItem