import React from 'react'
import styled from 'styled-components'
import { rootStyles } from '../style/shared'

const MainContentPane = styled.div`
    max-width: ${({ maxWidth }) => maxWidth ? `${maxWidth}px` : `${rootStyles.width.appMaxWidth}px`};
    margin: 0 auto;
    min-height: 500px;
    padding: ${rootStyles.padding.lg}px ${rootStyles.padding.md}px;
`

const MainContent = ({ children, maxWidth }) => {
    return (
        <MainContentPane maxWidth={maxWidth}>{children}</MainContentPane>
    )
}

export default MainContent