import React from 'react'
// import component 👇
import Drawer from 'react-modern-drawer'
//import styles 👇
import 'react-modern-drawer/dist/index.css'
import { useSidebarDrawerContext } from '../../hooks/useSidebarDrawerContext'
import { AiOutlineLogout } from 'react-icons/ai'
import Flex from '../../components/Flex'
import { rootStyles } from '../../style/shared'
import Button from '../../components/Button'
import GoogleDocsBtn from '../../pages/home/components/GoogleDocsBtn'
import { RiCloseLine } from 'react-icons/ri'
import Div from '../../components/Div'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AUTH_PAGE, RESERVATIONS_PAGE } from '../../routes/navigation_routes'

const SidebarDrawer = () => {
    const { visible, closeSidebarDrawer } = useSidebarDrawerContext()
    const { currentUser } = useSelector(state => state.user)
    const navigate = useNavigate()
    const toggleDrawer = () => {
        // setIsOpen((prevState) => !prevState)
    }

    return (
        <>
            <Drawer
                open={visible}
                onClose={toggleDrawer}
                direction='left'
                className=''
            >
                <Div padding={10}>
                    <Div mb={20}>
                        <Flex justify={"flex-end"}>
                            {/* <Button
                                        onClick={() => closeSidebarDrawer()}
                                        letterSpacing={3}
                                        fontSize={rootStyles.fontSize.sm - 2}
                                        fontWeight={500}
                                        textTransform="uppercase"
                                        paddingHorizontal={15}
                                        borderWidth={2}
                                        borderStyle="solid"
                                        borderRadius={rootStyles.borderRadius.xl}
                                        backgroundColor={rootStyles.colors.primary}
                                        color={rootStyles.colors.action}
                                    > */}
                            <RiCloseLine onClick={() => closeSidebarDrawer()} size={25} />
                            {/* </Button> */}
                        </Flex>
                    </Div>
                    <Flex direction='column' gap={10}>
                        {
                            (!currentUser?.access_token && !currentUser?.user?.id) ? <>
                                <Button
                                    letterSpacing={3}
                                    fontSize={rootStyles.fontSize.sm - 2}
                                    fontWeight={500}
                                    textTransform="uppercase"
                                    paddingHorizontal={2}
                                    borderWidth={2}
                                    borderStyle="solid"
                                    borderColor={rootStyles.colors.action}
                                    borderRadius={rootStyles.borderRadius.xl}
                                    backgroundColor={rootStyles.colors.primary}
                                    color={rootStyles.colors.action}
                                    hoverBackgroundColor={rootStyles.colors.action}
                                    hoverColor={rootStyles.colors.primary}
                                    height={20}
                                >
                                    S'identifier
                                </Button> 
                                <GoogleDocsBtn
                                    label="Devenir Partenaire"
                                    fontSize={rootStyles.fontSize.sm - 2}
                                />
                            </> :
                                <>
                                    <Button
                                        onClick={() => {
                                            closeSidebarDrawer()
                                            return navigate(AUTH_PAGE + RESERVATIONS_PAGE)
                                        }}
                                        letterSpacing={3}
                                        fontSize={rootStyles.fontSize.sm - 2}
                                        fontWeight={500}
                                        textTransform="uppercase"
                                        paddingHorizontal={20}
                                        borderWidth={2}
                                        borderStyle="solid"
                                        borderColor={rootStyles.colors.action}
                                        borderRadius={rootStyles.borderRadius.xl}
                                        backgroundColor={rootStyles.colors.primary}
                                        color={rootStyles.colors.action}
                                        hoverBackgroundColor={rootStyles.colors.action}
                                        hoverColor={rootStyles.colors.primary}
                                        height={20}
                                    >
                                        Mes reservations
                                    </Button>
                                    <GoogleDocsBtn
                                        label="Devenir Partenaire"
                                        fontSize={rootStyles.fontSize.sm - 2}
                                    />
                                    <Button
                                letterSpacing={3}
                                fontSize={rootStyles.fontSize.sm - 2}
                                fontWeight={500}
                                textTransform="uppercase"
                                paddingHorizontal={10}
                                borderWidth={2}
                                borderStyle="solid"
                                borderColor={"red"}
                                borderRadius={rootStyles.borderRadius.xl}
                                backgroundColor={rootStyles.colors.primary}
                                color={"red"}
                                height={20}
                            >
                                    <Flex justify={"center"} align={"center"}>
                                        <AiOutlineLogout />   <span>Deconnexion</span>
                                </Flex>
                            </Button>
                                </>
                        }

                    </Flex>
                </Div>
            </Drawer>
        </>
    )
}

export default SidebarDrawer