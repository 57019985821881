import { kiliyaApi } from "../../../../redux/rtkquery";

const historiqueReservationApi = kiliyaApi.injectEndpoints({
    endpoints: (builder) => ({
        reservationHistorique: builder.query({
            query: (userId) => `front/client/${userId}/appointments`,
            providesTags: ["Reservations"]
        }),
    })
})
export const { useReservationHistoriqueQuery } = historiqueReservationApi