import React, { useState } from 'react'
import Input from '../../../../components/Input'
import Div from '../../../../components/Div'
import Flex from '../../../../components/Flex'
import withLogin from '../../../../features/auth/withLogin'
import Button from '../../../../components/Button'
import { rootStyles } from '../../../../style/shared'
import { useForm } from 'react-hook-form'
import { errorMsg } from '../../../../helpers/errorMsg'
import { useNavigate } from 'react-router-dom'
import { AUTH_PAGE, SALONS_PAGE, SALON_RESERVATION_PAGE } from '../../../../routes/navigation_routes'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentUser } from '../../../../redux/features/userslice'
import { setFrom } from '../../../../redux/features/navigationSlice'

const WrappedSignInAndRedirectToReservation = ({ login }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { currentSalon } = useSelector(state => state.salons)
    const {
        register,
        handleSubmit,
        // watch,
        formState: { errors },
    } = useForm({
        values: {
            login: "",
            password: ""
        }
    })
    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = (data) => {
        setIsLoading(true)
        login(data).then(res => {
            dispatch(setCurrentUser(res))
            dispatch(setFrom(AUTH_PAGE + SALONS_PAGE + currentSalon.id + "/" + SALON_RESERVATION_PAGE))
            return navigate(AUTH_PAGE + SALONS_PAGE + currentSalon.id + "/" + SALON_RESERVATION_PAGE)
        }).catch(err => {
            errorMsg(err.data.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Flex align={"center"} gap={10} flex={1} wrap={"wrap"}>
                <Input
                    label={"Email"}
                    placeholder={"Email"}
                    type={"email"}
                    error={errors.email?.message}
                    register={{ ...register("login", { required: "Email obligatoire" }) }}
                />
                <Input
                    isPassword
                    label={"Mot de passe"}
                    placeholder={"Mot de passe"}
                    type={"password"}
                    error={errors.password?.message}
                    register={{ ...register("password", { required: "Mot de passe obligatoire" }) }}
                />
            </Flex>
            <Div mt={20} mb={20}>
                <Button
                    width={100}
                    letterSpacing={3}
                    fontSize={rootStyles.fontSize.sm}
                    fontWeight={600}
                    textTransform="uppercase"
                    paddingVertical={rootStyles.padding.md}
                    borderWidth={2}
                    borderStyle="solid"
                    borderColor={rootStyles.colors.action}
                    borderRadius={rootStyles.borderRadius.xl}
                    backgroundColor={rootStyles.colors.action}
                    color={rootStyles.colors.primary}
                // hoverBackgroundColor={rootStyles.colors.primary}
                // hoverColor={rootStyles.colors.action}
                // width={100}
                >
                    {
                        isLoading ? "Connexion en cours..." : "Connexion"
                    }
                </Button>
            </Div>
        </form >
    )
}

const SignInAndRedirectToReservation = withLogin(WrappedSignInAndRedirectToReservation)

export default SignInAndRedirectToReservation