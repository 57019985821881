import React, { useRef } from 'react'
import styled from 'styled-components'
import { rootStyles } from '../../../style/shared'
import Flex from '../../../components/Flex'
import SelectOptions from '../../../components/SelectOptions';
import Dot from '../../../components/Dot';
import { useDispatch, useSelector } from 'react-redux';
import { addDateToReservation, addEmployeToReservation, deleteReservation } from '../../../redux/features/reservationSlice';
import Div from '../../../components/Div';
import FadeInSpring from '../../../components/FadeInSpring';
import AccordionContainer from '../../../components/AccordionContainer';
import AccordionItem from '../../../components/AccordionItem';

const CardPane = styled.div`
    padding: ${rootStyles.padding.lg}px;
    background-color: ${rootStyles.colors.primary};
    box-shadow: 0 4px 8px -2px rgba(26, 27, 31, 0.3);
    margin-bottom: 15px;
    border-radius: 8px
`
const TitlePane = styled.span`
    font-size: ${rootStyles.fontSize.mg}px
`
const DurationPane = styled.span`
    color: ${rootStyles.colors.avisColor}
`
const PricePane = styled.span`
    font-size: ${rootStyles.fontSize.sm}px;
    font-weight: 600 !important;
`
export const DeleteBtnPane = styled.button`
    all: unset;
    border-bottom: 1px solid ${rootStyles.colors.action};
    color: ${rootStyles.colors.action};
    transition: all 0.2s ease;
    
    &:active{
        transform: scale(.9)
    }
`
const TImeContaintPane = styled.span`
    background-color: #f7f7f7;
    display: block;
    padding: 8px 10px;
    text-align: center;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    max-width: 70px;
    margin: 10px auto;

    &:hover{
        background-color: ${rootStyles.colors.primary};
        color: ${rootStyles.colors.white};
    }
`
const CalendarPane = styled.div`
    background-color: ${rootStyles.colors.primary};
    padding: ${rootStyles.padding.lg}px ${rootStyles.padding.lg + 50}px;
    border-radius: 8px;
    box-shadow: 0 4px 8px -2px rgba(26, 27, 31, 0.3);
    overflow: auto;
    width: 100%;

    @media (max-width: 640px) {
        padding: ${rootStyles.padding.lg}px ${rootStyles.padding.lg + 50}px;
        display: none;
    }
`
const MobileCalendarPane = styled.div`
    display: none;
    @media screen and (max-width: 640px) {
        display: block;
    }
`
const DayPane = styled.p`
    font-size: ${rootStyles.fontSize.md - 5}px;
    font-weight: 600;
    text-align: center;
`
// const DatePane = styled.p`
//     text-align: center;
// `
// const OverflowPane = styled.div`
//     width: 100%;
//     overflow: auto;
// `

const ReservationCardItem = ({ reservation }) => {
    const { currentSalon } = useSelector(state => state.salons)
    const dispatch = useDispatch()
    const cardRef = useRef()


    const employesOptions = reservation?.reservation?.employee?.map(employee => {
        return { value: employee.id, label: employee.first_name + ' ' + employee.last_name }
    }) || []

    // console.log({ currentSalon, employesOptions, reservation });

    const handleOnChangeEmploye = (value) => {
        const selectedEmployee = reservation?.reservation?.employee.filter(employee => employee.id === value.value)[0]
        // ! pour animer le rendu des cards
        cardRef?.current?.handleClick()

        return dispatch(addEmployeToReservation({
            employe: value,
            reservationLineId: reservation.id,
            selectedEmployee,
            idSalon: currentSalon.id
        }))
    }

    return (
        <FadeInSpring style={{ padding: 5 }}>
            <CardPane key={reservation.id}>
                <Flex justify={"space-between"} align={"center"} wrap={"wrap"} gap={20}>
                    <Flex direction='column' minwidth={200}>
                        <TitlePane>{reservation.reservation.name}</TitlePane>
                        <Flex align={"center"} wrap={"wrap"}>
                            <Flex align={"center"}>
                                <DurationPane>{reservation.reservation.duration}min</DurationPane>
                                <Dot />
                                <PricePane>{reservation.reservation.price} FCFA</PricePane>
                            </Flex>
                            {
                                reservation.date && <>
                                    <Dot />
                                    <Flex align={"center"} minwidth={200}>
                                        <Div>{reservation.date.weekDay}</Div>
                                        <TImeContaintPane >
                                            {reservation.date.hour}
                                        </TImeContaintPane>
                                        <Div>avec</Div>
                                        <PricePane>{reservation.employe.label}</PricePane>
                                    </Flex>
                                </>
                            }
                        </Flex>
                    </Flex>
                    <Flex align={"center"} gap={20} wrap={"wrap"}>
                        {
                            !reservation?.date && <SelectOptions
                                defaultValue={reservation?.employe}
                                width={250}
                                onChange={(value) => handleOnChangeEmploye(value)}
                                placeholder={"Avec qui ?"}
                                options={employesOptions}
                                padding={0}
                                borderWidth={1}
                            />
                        }
                        <DeleteBtnPane
                            onClick={() => dispatch(deleteReservation({ reservationLineId: reservation.id, idSalon: currentSalon.id }))}
                        >Supprimer</DeleteBtnPane>
                    </Flex>
                </Flex>
            </CardPane>

            {
                (!reservation.date && reservation?.employe) && <FadeInSpring ref={cardRef}>
                    <CalendarPane opacity={true}>
                    <Flex gap={30} justify={"space-evenly"} minwidth={500}>
                        {
                                reservation?.employeFormaDays.length ? reservation.employeFormaDays?.map(day => {
                                    return <Flex key={day.weekDay} direction='column' align={"space-between"} flex={1} gap={10}>
                                    <Div>
                                        <Div>
                                            <DayPane>{day.weekDay}</DayPane>
                                        </Div>
                                        {/* <Div>
                                            <DatePane>
                                                {new Date(day.date).toLocaleDateString("fr-Fr", {
                                                    month: "short", // month
                                                    day: "numeric",
                                                })}
                                            </DatePane>
                                        </Div> */}
                                    </Div>
                                    <Div>
                                        {
                                            day.available_hours.map(hour => {
                                                return <TImeContaintPane onClick={() => {
                                                    return dispatch(addDateToReservation({
                                                        date: {
                                                            // fullDate: new Date(day.date).toLocaleString("fr-Fr", {
                                                            //     day: "2-digit",
                                                            //     month: "2-digit",
                                                            //     year: "numeric"
                                                            // }),
                                                            fullDate: day.date,
                                                            weekDay: day.weekDay,
                                                            hour
                                                        },
                                                        reservationLineId: reservation.id,
                                                        idSalon: currentSalon.id
                                                    }))
                                                }}>{hour}</TImeContaintPane>
                                            })
                                        }
                                    </Div>
                                </Flex>
                            }) : <p>{reservation.employe.label} n'a pas encore de jours de travail</p>
                        }
                        </Flex>
                </CalendarPane>
                </FadeInSpring>
            }
            {
                (!reservation.date && reservation?.employe) && <MobileCalendarPane>
                    <h4>Choisissez la date</h4>
                    {
                        reservation?.employeFormaDays.length ? reservation.employeFormaDays?.map(day => {
                            return <Div mb={10}>
                                <AccordionContainer>
                                    <AccordionItem accordionTitle={day.weekDay}>
                                        <Flex wrap={"wrap"} justify={"flex-start"}>
                                            {
                                                day.available_hours.length ? day.available_hours.map(hour => {
                                                    return <TImeContaintPane onClick={() => {
                                                        return dispatch(addDateToReservation({
                                                            date: {
                                                                // fullDate: new Date(day.date).toLocaleString("fr-Fr", {
                                                                //     day: "2-digit",
                                                                //     month: "2-digit",
                                                                //     year: "numeric"
                                                                // }),
                                                                fullDate: day.date,
                                                                weekDay: day.weekDay,
                                                                hour
                                                            },
                                                            reservationLineId: reservation.id,
                                                            idSalon: currentSalon.id
                                                        }))
                                                    }}>{hour}</TImeContaintPane>
                                                }) : <p>Pas disponible</p>
                                            }
                                        </Flex>
                                    </AccordionItem>

                                </AccordionContainer>
                            </Div>
                        }) : <p className='text-danger'>{reservation.employe.label} n'a pas encore de jours de travail</p>
                    }
                </MobileCalendarPane>
            }
        </FadeInSpring>
    )
}

export default ReservationCardItem