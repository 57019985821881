import { createSlice } from "@reduxjs/toolkit";

const reservationSlice = createSlice({
    name: "reservations",
    initialState: {
        salonsWithReservations: [],
        reservations: [],
        currentSalonReservations: []
    },
    reducers: {
        // ! cette methode sera appele a chaque fois qu'on est sur la page d'un salon(SalonDetailsPage)
        setReservationsForCurrentSalon: (state, { payload }) => {
            let reservationsForCurrentSalon = []
            // !check if currentSalon has reservations
            const checkIfIdSalonExistsInsalonsWithReservations = state.salonsWithReservations.filter(reservation => reservation.idSalon === payload.idSalon)
            if (checkIfIdSalonExistsInsalonsWithReservations.length) {
                reservationsForCurrentSalon = checkIfIdSalonExistsInsalonsWithReservations[0].reservations
                return {
                    ...state,
                    currentSalonReservations: [...reservationsForCurrentSalon]
                }
            }
            return {
                ...state,
                currentSalonReservations: []
            }
        },
        addReservation: (state, { payload }) => {
            const copySalonsWithReservations = [...state.salonsWithReservations]
            const checkIfIdSalonExistsInsalonsWithReservations = copySalonsWithReservations.find(reservation => reservation.idSalon === payload.idSalon)
            // ! si le salon existe deja sur la listes des salons qui on une ou des reservations, on append la nouvelles reservation sur "reservations" du salon
            if (checkIfIdSalonExistsInsalonsWithReservations) {
                const reservationIndex = copySalonsWithReservations.findIndex(reservation => reservation.idSalon === payload.idSalon)
                const newReservations = [...copySalonsWithReservations[reservationIndex].reservations, payload.reservation]
                copySalonsWithReservations[reservationIndex] = { idSalon: payload.idSalon, reservations: newReservations }
                return {
                    ...state,
                    salonsWithReservations: [...copySalonsWithReservations],
                    currentSalonReservations: newReservations
                }
            }

            return {
                ...state,
                reservations: [...state.reservations, payload],
                salonsWithReservations: [...state.salonsWithReservations, { idSalon: payload.idSalon, reservations: [payload.reservation] }],
                currentSalonReservations: [payload.reservation]
            }
        },
        addEmployeToReservation: (state, { payload }) => {
            const copySalonsWithReservations = [...state.salonsWithReservations]
            const copyCurrentSalonsReservations = [...state.currentSalonReservations]
            // ! lets find the index of the currentSalon in salonsWithReservations
            const currentSalonReservationIndex = copySalonsWithReservations.findIndex(reservation => reservation.idSalon === payload.idSalon)
            // !find the selected reservation to add "employe" property to the current reservation
            let reservation = state.currentSalonReservations.find(reservation => reservation.id === payload.reservationLineId)
            const reservationIndex = state.currentSalonReservations.findIndex(reservation => reservation.id === payload.reservationLineId)

            const working_hours = payload.selectedEmployee.working_hours

            const format_days = working_hours.map((day) => {
                // console.log({ day });
                let weekDay = day.day
                let date = day.start
                const available_hours = []

                if (day.enable) {
                    let startTimeInMinutes = new Date(day.start).getHours() * 60 + new Date(day.start).getMinutes();
                    let endTimeInMinutes = new Date(day.end).getHours() * 60 + new Date(day.start).getMinutes();

                    const TIME_INTERVAL = 30
                    let nextTimeInMin = startTimeInMinutes
                    while (nextTimeInMin < endTimeInMinutes) {
                        let nextTimeInHour = Math.floor((nextTimeInMin) / 60)
                        let nextTimeInMinute = nextTimeInMin % 60

                        nextTimeInMinute = nextTimeInMinute <= 9 ? `0${nextTimeInMinute}` : nextTimeInMinute
                        available_hours.push(nextTimeInHour + ":" + nextTimeInMinute)

                        nextTimeInMin += TIME_INTERVAL
                    }
                }


                return {
                    weekDay,
                    date,
                    available_hours
                }
            })


            //! add the employe to the current reservation
            const reservationWithEmploye = {
                ...reservation,
                employe: payload.employe,
                employeFormaDays: format_days
            }
            copyCurrentSalonsReservations[reservationIndex] = reservationWithEmploye

            copySalonsWithReservations[currentSalonReservationIndex] = { idSalon: payload.idSalon, reservations: copyCurrentSalonsReservations }

            return {
                ...state,
                currentSalonReservations: [...copyCurrentSalonsReservations],
                salonsWithReservations: copySalonsWithReservations
            }
        },
        deleteReservation: (state, { payload }) => {
            const copySalonsWithReservations = [...state.salonsWithReservations]
            const currentSalonReservationIndex = copySalonsWithReservations.findIndex(reservation => reservation.idSalon === payload.idSalon)
            //! create reservation copy for immutability
            const copyCurrentSalonsReservations = [...state.currentSalonReservations]
            //!find the selected reservation to delete
            const reservationIndex = state.currentSalonReservations.findIndex(reservation => reservation.id === payload.reservationLineId)
            //! delete the selected reservation
            copyCurrentSalonsReservations.splice(reservationIndex, 1)
            copySalonsWithReservations[currentSalonReservationIndex] = { idSalon: payload.idSalon, reservations: copyCurrentSalonsReservations }
            return {
                ...state,
                currentSalonReservations: [...copyCurrentSalonsReservations],
                salonsWithReservations: copySalonsWithReservations
            }
        },
        addDateToReservation: (state, { payload }) => {
            const copySalonsWithReservations = [...state.salonsWithReservations]
            const currentSalonReservationIndex = copySalonsWithReservations.findIndex(reservation => reservation.idSalon === payload.idSalon)
            //! create reservation copy for immutability
            const copyCurrentSalonsReservations = [...state.currentSalonReservations]
            //!find the selected reservation to add "date" property to the current reservation
            let reservation = state.currentSalonReservations.find(reservation => reservation.id === payload.reservationLineId)
            const reservationIndex = state.currentSalonReservations.findIndex(reservation => reservation.id === payload.reservationLineId)
            //! add the date to the current reservation
            const reservationWithDate = {
                ...reservation,
                date: payload.date
            }
            copyCurrentSalonsReservations[reservationIndex] = reservationWithDate
            copySalonsWithReservations[currentSalonReservationIndex] = { idSalon: payload.idSalon, reservations: copyCurrentSalonsReservations }
            return {
                ...state,
                currentSalonReservations: [...copyCurrentSalonsReservations],
                salonsWithReservations: copySalonsWithReservations
            }
        },
        resetCurrentSalonReservations: (state, { payload }) => {
            const copySalonsWithReservations = [...state.salonsWithReservations]
            const newSalonsWithReservations = copySalonsWithReservations.filter(reservations => reservations.idSalon !== payload.idSalon);
            return {
                ...state,
                currentSalonReservations: [],
                salonsWithReservations: newSalonsWithReservations
            }
        } 
    }
})

export const {
    addReservation,
    addEmployeToReservation,
    deleteReservation,
    addDateToReservation,
    setReservationsForCurrentSalon,
    resetCurrentSalonReservations
} = reservationSlice.actions
export default reservationSlice.reducer