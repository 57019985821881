import { useSelector } from 'react-redux'

const useTotalReservation = () => {
    const { currentSalonReservations } = useSelector(state => state.reservations)
    const totalReservation = currentSalonReservations.reduce((total, reservation) => {
        return total + Number(reservation.reservation.price)
    }, 0)
    return { totalReservation }
}

export default useTotalReservation