import { animated, useSpring } from '@react-spring/web'
import { forwardRef, useImperativeHandle } from 'react'

const FadeInSpring = ({ children, styles }, ref) => {
    const [springs, api] = useSpring(() => ({
        from: {
            opacity: 0,
            y: 6,
        },
        to: {
            opacity: 1,
            y: 0,
        },
    }))

    useImperativeHandle(ref, () => {
        return {
            handleClick() {
                api.start({
                    from: {
                        opacity: 0,
                        y: 6,
                    },
                    to: {
                        opacity: 1,
                        y: 0,
                    },
                })
            }
        }
    })

    return (
        <animated.div
            style={{
                ...springs,
                ...styles,
            }}
        >
            {children}
        </animated.div>
    )
}

export default forwardRef(FadeInSpring)