import React from 'react'
import styled from 'styled-components'
import { rootStyles } from '../style/shared'

const DotPane = styled.div`
    width: 5px;
    height: 5px;
    border-radius: 50px;
    background-color: ${rootStyles.colors.inputBorderColor}
`

const Dot = () => {
    return (
        <DotPane></DotPane>
    )
}

export default Dot