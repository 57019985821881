import React, { useState } from "react"
import styled from "styled-components"
import { rootStyles } from "../style/shared"
import Flex from "./Flex"
import { BsEye, BsEyeSlash } from "react-icons/bs"
import Div from "./Div"

const InputPane = styled.input`
    padding: 0 ${rootStyles.padding.md}px;
    margin: 0;
    width: 100%;
    min-width: 300px;
    height: 49px;
    color: ${rootStyles.colors.action};
    font-weight: 500;
    border: 2px solid ${rootStyles.colors.inputBorderColor};
    border-color: ${({ error }) => error ? "red" : rootStyles.colors.inputBorderColor};
    border-radius: ${rootStyles.borderRadius.md}px;
    transition: all 200ms ease-in-out;

    &:focus {
        outline: none;
        border: 2px solid ${rootStyles.colors.action};
        box-shadow: none;
    }

    &:placeholder{
        // color:${({ error }) => error ? "red" : rootStyles.colors.inputPlaceHolderColor};
        font-size: ${rootStyles.fontSize.sm}px;
        font-weight: 300;
    }
`
const LabelPane = styled.label`
    font-size: ${rootStyles.fontSize.sm}px;
    font-weight: 600;

    &::after{
        content: ${({ optional }) => optional ? "dd" : " *"};
    };

    // color: ${({ error }) => error ? "red" : "inherit"};
    // color: red;
`
// const SpanErrorPane = styled.span`
//     font-size: ${rootStyles.fontSize.sm}px;
//     color: red;
// `

const Input = ({ type, placeholder, label, error = null, register, optional, isPassword }) => {
    const [passwordType, setPasswordType] = useState("password")

    return <Flex direction="column" flex={1}>
        {
            label && <Flex justify={"space-between"} align={"center"}>
                {<LabelPane optional={optional} error={error}>{label}</LabelPane>}
                {/* {
                error && <SpanErrorPane>{error}</SpanErrorPane>
            } */}
            </Flex>
        }
        <Div>
            <InputPane
                type={type === "password" ? passwordType : type}
                placeholder={placeholder}
                error={error}
                {...register}
            />

            {
                isPassword ?
                    type === "password" ? passwordType === "password" ?
                        <BsEye
                            onClick={() => setPasswordType("text")}
                            size={20}
                            style={{ position: "absolute", right: "10px", top: "12px" }}
                        /> : <BsEyeSlash
                            onClick={() => setPasswordType("password")}
                            size={20}
                            style={{ position: "absolute", right: "10px", top: "12px" }}
                        /> :
                        null :
                    null
            }
        </Div>
    </Flex>
}

export default Input