export const rootStyles = {
    colors: {
        primary: "#FFFFFF",
        black: "#000000",
        secondary: "",
        action: "#45007D",
        tercary: "#836D38",
        quaternary: "#666666",
        appBgColor: "#FAFAFA",
        bannerLinearBgColor: "#7100B2",
        inputPlaceHolderColor: "#515356",
        inputBorderColor: "#979797",
        sectionH2: "#333333",
        avisColor: "#919191",
        avisNumberColor: "#E3A12A",
        salonServiceBorder: "#EBEBEB",
        salonDetailsColor: "#343434",
        badgeBgColor: "#DFCFEC",
        quinary: "",
        senary: "",
    },
    width: {
        appMaxWidth: 1200,
        childrenMaxWidth: 900
    },
    borderRadius: {
        sm: 4,
        md: 6,
        lg: 30,
        xl: 40
    },
    padding: {
        sm: 8,
        md: 12,
        lg: 24,
        xl: 32
    },
    fontSize: {
        sm: 14,
        md: 20,
        lg: 30,
        xl: 45
    },
    shadow: {
        card: "0 4px 8px -2px rgba(26, 27, 31, 0.3)"
    }
}