import React from 'react'
import styled from 'styled-components'

const DivPane = styled.div`
    margin-top: ${({ mt }) => `${mt}px`};
    margin-bottom: ${({ mb }) => `${mb}px`};
    margin-left: ${({ ml }) => `${ml}px`};
    margin-right: ${({ mr }) => `${mr}px`};
    background-color: ${({ bg }) => bg};
    padding: ${({ p }) => `${p}px`};
    // background-color: red
    position: relative;
    // width: 100%
`

const Div = ({ mt = 0, mb = 0, ml = 0, mr = 0, padding: p, bg, children, className }) => {
    return (
        <DivPane
            mt={mt}
            mb={mb}
            ml={ml}
            mr={mr}
            bg={bg}
            p={p}
            className={className}
        >
            {children}
        </DivPane>
    )
}

export default Div