import { createSlice } from "@reduxjs/toolkit";

const salonSlice = createSlice({
    name: "salons",
    initialState: { currentSalon: {} },
    reducers: {
        setCurrentSalon: (state, { payload }) => {
            return {
                ...state,
                currentSalon: payload
            }
        }
    }
})

export const { setCurrentSalon } = salonSlice.actions
export default salonSlice.reducer