import React, { useState } from 'react'
import { IoMdAdd } from "react-icons/io"

import { rootStyles } from '../../style/shared';
import Flex from '../../components/Flex';
import Button from '../../components/Button';
import ReservationCardItem, { DeleteBtnPane } from './components/ReservationCardItem';
import ReservationCard from './components/ReservationCard';
import AddNewReservation from './components/AddNewReservation';
import Div from '../../components/Div';
import { Message } from 'semantic-ui-react';
import useTotalReservation from '../../hooks/useTotalReservation';

const ReservationFeature = () => {
    const [showAddNewReservation, setShowAddNewReservation] = useState()
    const { totalReservation } = useTotalReservation()

    return (
        <div>
            <ReservationCard
                reservationCardItem={({ reservation }) => <ReservationCardItem key={reservation.id} reservation={reservation} />}
            />
            <Div mt={40}>
                <Flex justify={"flex-end"}>
                    <Message
                        header={`Total: ${totalReservation} FCFA`}
                    />
                </Flex>
            </Div>
            <Div mt={50}>
                {
                    !showAddNewReservation && <Button
                        onClick={() => setShowAddNewReservation(true)}
                        letterSpacing={1}
                        fontSize={rootStyles.fontSize.sm}
                        paddingHorizontal={0}
                        fontWeight={500}
                        textTransform="normal"
                        borderWidth={2}
                        borderStyle="solid"
                        borderColor={rootStyles.colors.action}
                        borderRadius={rootStyles.borderRadius.sm}
                        backgroundColor={rootStyles.colors.action}
                        color={rootStyles.colors.primary}
                        width={100}
                    >
                        <Flex align={"center"} justify={"center"}>
                            <IoMdAdd size={25} />
                            <span>Ajouter une réservation à la suite</span>
                        </Flex>
                    </Button>
                }
                {
                    showAddNewReservation && <>
                        <Flex justify={"flex-end"}>
                            <DeleteBtnPane
                                onClick={() => setShowAddNewReservation(false)}>
                                Supprimer
                            </DeleteBtnPane>
                        </Flex>
                        <AddNewReservation setShowAddNewReservation={setShowAddNewReservation} />
                    </>
                }
            </Div>
        </div>
    )
}

export default ReservationFeature