import React from 'react'
import Logo from '../../../components/Logo'
import Div from '../../../components/Div'

const PageLoading = () => {
    return (
        <div style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <Div>
                <Logo />
            </Div>
        </div>
    )
}

export default PageLoading