import React from 'react'
import styled from 'styled-components'
import { AiOutlineClockCircle } from "react-icons/ai"

import { rootStyles } from '../../../../style/shared'
import Flex from '../../../../components/Flex'
import Button from '../../../../components/Button'
import Div from '../../../../components/Div'
import Dot from '../../../../components/Dot'

const ContainerPane = styled.div`
    border-bottom: 1px solid ${rootStyles.colors.salonServiceBorder};
    padding: 12px 0;
`
const ServiceTitlePane = styled.p`
    font-size: ${rootStyles.fontSize.sm}px;
`
export const ServiceTimePane = styled.h5`
    font-size: ${rootStyles.fontSize.sm}px;
    color: ${rootStyles.colors.avisColor};
    margin-bottom: 0
`
const PriceSmallPane = styled.span`
    color: ${rootStyles.colors.avisColor};
    font-size: ${rootStyles.fontSize.sm}px;
`
const PriceNormalPane = styled.span`
    color: ${rootStyles.colors.action};
    font-size: ${rootStyles.fontSize.sm}px;
`
const SpanPane = styled.span`
    font-size: ${rootStyles.fontSize.sm}px;
    font-weight: 300;
`

const SalonServiceCardItem = ({ name, description = "", duration, price, has_promotion, onClickBtn }) => {
    return (
        <ContainerPane>
            <Flex justify={"space-between"} align={"center"} wrap={"wrap"} gap={8}>
                <Div>
                    <ServiceTitlePane>{name}</ServiceTitlePane>
                    <SpanPane>{description}</SpanPane>
                </Div>
                <Flex
                    // alignSelf={"flex-end"}
                    align={"center"}
                    gap={10}
                    justify={"space-between"}
                    minwidth={300}
                    wrap={"wrap"}
                >
                    <ServiceTimePane>
                        <Flex align={"center"} gap={5}>
                            <AiOutlineClockCircle /> {duration} min
                        </Flex>
                    </ServiceTimePane>
                    <Dot />
                        {
                            has_promotion && <PriceSmallPane>20 FCFA</PriceSmallPane>
                        }
                        <PriceNormalPane>{price} FCFA</PriceNormalPane>
                    <Button
                        responsive
                            onClick={onClickBtn}
                            letterSpacing={2}
                            fontSize={rootStyles.fontSize.sm}
                            fontWeight={500}
                            textTransform="normal"
                            borderWidth={2}
                            paddingHorizontal={8}
                            borderStyle="solid"
                            borderColor={rootStyles.colors.action}
                            borderRadius={rootStyles.borderRadius.sm}
                            backgroundColor={!true ? rootStyles.colors.action : rootStyles.colors.primary}
                            color={!true ? rootStyles.colors.primary : rootStyles.colors.action}
                            height={20}
                        >
                        Choisir
                        </Button>
                </Flex>
            </Flex>
        </ContainerPane>
    )
}

export default SalonServiceCardItem