import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { MdOutlineArrowBack } from "react-icons/md"

import { rootStyles } from '../style/shared'

const SpanPane = styled.div`
    display: inline-block;
    border-bottom: 1px solid ${rootStyles.colors.inputBorderColor};
    font-size: ${rootStyles.fontSize.md}px;
    cursor: pointer;

    &:hover{
        opacity: .7;
    }

       @media screen and (max-width: 768px) {
        font-size: ${rootStyles.fontSize.md - 3}px;
    }
`

const GoBack = ({ label, navigateTo }) => {
    const navigate = useNavigate()
    return (
        <SpanPane onClick={() => navigateTo ? navigateTo() : navigate(-1)}><MdOutlineArrowBack size={25} />{label}</SpanPane>
    )
}

export default GoBack